<template>
  <v-container
    fluid
    grid-list-{xs
    through
    xl}
  >
    <v-layout align-center justify-center>
      <div>
        <h3 style="color:black">
          Our marvelous MagicGraphs assist you in every step of your learning journey.
        </h3>
      </div>
    </v-layout>
    <br>
    <v-layout
      justify-center
      align-center
      class="mb-3 pa-2"
    >
      <v-flex
        xs12
        xl4
        sm4
        md4
        lg4
      >
        <v-layout justify-center align-center>
          <v-img
            src="/assets/eggOne.svg"
            max-height="40px"
            max-width="40px"
            contain
          />
        </v-layout>
        <v-layout class="mt-1" justify-center align-center>
          <h5 style="color:black">
            Build Concepts
          </h5>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        sm4
        md4
        lg4
        xl4
      >
        <v-layout justify-center align-center>
          <v-img
            src="/assets/eggTwo.svg"
            max-height="40px"
            max-width="40px"
            contain
          />
        </v-layout>
        <v-layout class="mt-1" justify-center align-center>
          <h5 style="color:black">
            Practice Problems
          </h5>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        sm4
        md4
        lg4
        xl4
      >
        <v-layout justify-center align-center>
          <v-img
            src="/assets/eggThree.svg"
            max-height="40px"
            max-width="40px"
            contain
          />
        </v-layout>
        <v-layout class="mt-1" justify-center align-center>
          <h5 style="color:black">
            Test Knowledge
          </h5>
        </v-layout>
      </v-flex>
    </v-layout>
    <divide-content />
    <br>
    <v-layout align-center justify-center>
      <div class="header">
        <b>Let's Get Started</b>
      </div>
    </v-layout>
    <v-layout align-center justify-center>
      <div>
        <h5 style="color:black">
          (Choose a topic to explore)
        </h5>
      </div>
    </v-layout>
    <br>
    <div v-for="(group, groupIndex) in topics" :key="groupIndex">
      <v-layout justify-start raw wrap>
        <v-flex
          v-for="(topic, topicIndex) in group"
          :key="topicIndex"
          xs12
          sm4
          md4
          lg4
          xl4
        >
          <v-hover>
            <v-card
              slot-scope="{ hover }"
              class="checkboard-card"
              :color="getColor(topic.isBlack)"
              :class="[`${hover ? 'checkboard-card-hover' : ''}`,
                       `elevation-${hover ? 17 : 0}`,
                       {'white--text': topic.isBlack}]"
              @click.native="topic.action"
            >
              <v-card-title primary-title>
                <div>
                  <div class="headline">
                    {{ topic.title }}
                  </div>
                  <v-icon class="lock">
                    {{ topic.icon }}
                  </v-icon>
                  <div class="spaned">
                    {{ topic.description }}
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import DivideContent from  './Divider.vue';
export default {
  name: 'Programming',
  components: {
    DivideContent
  },
  computed: {
    ...mapGetters({
      topics: 'navigation/programming'
    })
  },
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Programming';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newEng =true;
    this.$store.commit('navigation/replaceEng', newEng);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
  },
  methods: {
    getColor(isBlack) {
    return isBlack ? '#2E2E2E' : '#e0e0e0';
    }
  },
/*beforeRouteEnter (to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next(vm => { vm.$store.commit('navigation/leftArrow', false)});
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.$store.commit('navigation/leftArrow', true);
    console.log('lelele')
    next();
  },*/
  metaInfo: {
          title: 'Learn to Code',
          titleTemplate: '%s | Learn interactively',
          meta: [
                  {vmid: 'description', name: 'description', content: 'Takes a visually interactive approach to teach programming to high school students and teachers.'},
                  {vmid: 'keywords', name: 'keywords', content: 'Learn to code, visual interactive programming interface'}
                ],
          link: [{rel: 'canonical', href: 'https://edliy.com/programming'}]
           }
}
</script>
<style lang="scss">
@import '@/styles/screen-sizes.scss';
</style>
